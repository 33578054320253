import { lazy } from 'react';

export const LOGIN = "/login"

export const RESERVATION_DETAIL = "/reservation/:id"
export const RESERVATION_ADD = "/reservation/add"
export const RESERVATION_LIST = "/reservation/list"

export const HOME_PAGE = RESERVATION_LIST

export const routeList = [
    {
        path: LOGIN,
        component: lazy(() => import('./pages/Login'))
    },
    {
        path: RESERVATION_DETAIL,
        component: lazy(() => import('./pages/reservation/add'))
    },
    {
        path: RESERVATION_ADD,
        component: lazy(() => import('./pages/reservation/add'))
    },
    {
        path: RESERVATION_LIST,
        component: lazy(() => import('./pages/reservation/list'))
    }
]