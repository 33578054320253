import React from 'react';
import ReactDOM from 'react-dom/client';
import { Route, Routes, BrowserRouter as Router } from 'react-router-dom'
import { routeList } from './route'


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <Router>
        <Routes>
            {
                routeList.map((item) => (
                    <Route key={item.path} path={item.path} element={
                        <React.Suspense  >
                            <item.component />
                        </React.Suspense>
                    } />
                ))
            }
        </Routes>
    </Router>
);
